import React, { useContext, useState } from 'react';
import './ProductList.css';
import Product from '../product/Product';
import { products_light, products_night, products_3d_character_light, products_3d_character_night, products_3d_animation } from '../../data';
import { ThemeContext } from '../../context';
import YoutubeEmbed from './YoutubeEmbed';

function ProductList() {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    const [type, setType] = useState(0);

    const handleType = (ele) => {
        setType(ele);
    }

    return (
        <div className="pList">
            <div className="pList-buttons">
                <button style={{backgroundColor: darkMode ? "#282828" : "#ffffff", color: !darkMode ? "black" : "white"}} onClick={() => { handleType(0) }} className='pList-button'>Sketchs</button>
                <button style={{backgroundColor: darkMode ? "#282828" : "#ffffff", color: !darkMode ? "black" : "white"}} onClick={() => { handleType(1) }} className='pList-button'>3D Models</button>
                <button style={{backgroundColor: darkMode ? "#282828" : "#ffffff", color: !darkMode ? "black" : "white"}} onClick={() => { handleType(2) }} className='pList-button'>3D Animated</button>
            </div>
            <div className="pList-texts">
                <p className='pList-desc'>
                    These are some of the products I made during my self-study. If you have any questions, compliments, or criticisms for me, please email me in the contact section. 
                    Change the <strong style={{backgroundColor: darkMode ? "#ffffff" : "#282828", color: !darkMode ? "white" : "#282828"}} class='pList-desc-theme'>light - dark</strong> theme to see other artworks.
                </p>
            </div>
            {
                type === 0 ? (
                    <>
                        <div className="pList-list">
                            {darkMode ? products_night.map(item => (
                                <Product key={item.id} img={item.img} link={item.link} about={item.about} />
                            )) : products_light.map(item => (
                                <Product key={item.id} img={item.img} link={item.link} about={item.about} />
                            ))}
                        </div>
                    </>
                )
                : 
                type === 1 ? (
                    <>
                        <div className="pList-list">
                            {darkMode ? products_3d_character_night.map(item => (
                                <Product key={item.id} img={item.img} link={item.link} about={item.about} />
                            )) : products_3d_character_light.map(item => (
                                <Product key={item.id} img={item.img} link={item.link} about={item.about} />
                            ))}
                        </div>
                    </> 
                )
                : (
                    <>
                        <div className="pList-list">
                            {products_3d_animation.map(item => (
                                <YoutubeEmbed embedId={item.embedLink} about={item.about} />
                            ))}
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default ProductList;
