import React, { useContext, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import './About.css';
import Pc_coding from '../../img/pc-coding.webp';
import Pc_bachelor from '../../img/pc-bachelor.webp';
import Pc_animator from '../../img/pc-animator.webp';
import Pc_director from '../../img/director.webp';
import S_Connect from '../../img/s-cnt-cp.webp'
import SKDA_dirt from '../../img/SKDA_dirt.webp';
import SKDA_smoke from '../../img/SKDA_smoke.webp';
import Birdy from '../../img/birdy.webp';
import WF_s from '../../img/wf_s.webp';
import WF from '../../img/wf.webp';
import { ThemeContext } from '../../context';

const About = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    const [type, setType] = useState(0);

    const handleType = (ele) => {
        setType(ele);
    }

    return (
        <div className="about-total">
            <div className="about-buttons">
                <button style={{backgroundColor: darkMode ? "#282828" : "#ffffff", color: !darkMode ? "black" : "white"}} onClick={() => { handleType(0) }} className='about-button'>Life</button>
                <button style={{backgroundColor: darkMode ? "#282828" : "#ffffff", color: !darkMode ? "black" : "white"}} onClick={() => { handleType(1) }} className='about-button'>Career</button>
            </div>
            {
                type === 0 ? (
                    <>
                        <div className='about part_1'>
                            <div className="about-left">
                                <div className="about-card">
                                    <LazyLoad>
                                        <img src={Pc_bachelor} alt="" className="about-img" />
                                    </LazyLoad>
                                </div>
                            </div>
                            <div className="about-right">
                                <h1 className='about-title'>About me</h1>
                                <h2>╰‿╯ Education</h2>
                                <hr /><br />
                                <div className="about-desc about-content">
                                    Hanoi University of Science<br />
                                    Major: Computer and information science<br />
                                    GPA: 2.5
                                    <br />
                                    
                                    <br />
                                    Project: <br />
                                    ♢ Rat in a maze - using A* Algorithm <a className="link-git" href='https://github.com/Hokage-Itachi/Graph' target="_blank" rel="noreferrer">🔗</a> <br />
                                    ♢ Build API for Employee Management Web App and Mobile App <a className="link-git" href='https://github.com/dang007x/DevOpsRepo' target="_blank" rel="noreferrer">🔗</a> <br />
                                    ♢ Simulate satellites orbiting the Earth - Matlab <br />
                                    ♢ Build a Forum for School
                                    <br />
                                    <br />
                                    Activity: <br />
                                    ♢ Teambuilding, Blood Donation, Road to A+, Simple Sience <br />
                                    ♢ Student Science Conference
                                </div>
                            </div>
                        </div>
                        <div className='about part_2'>
                            <div className="about-left" >
                                <div className="about-desc about-content" style={{paddingLeft: '50px'}}>
                                    <h2>╰‿╯ Preparing for becoming a 3D Animator</h2> <hr />
                                    <br />
                                    <p>I get deep into this industry too late. When I get third I realized that IT is not for me, 
                                        then I slow down, take a deep breath and quit my current IT job. And my journey begins from there. <br />
                                        <br />
                                            ♢ Start from <strong>Google, YouTube</strong><br/>
                                            ---- I enjoy watching 3D animated movies, but I do not know what actually they are or how to make them. 
                                            Then I googled "how to make a 3D animated movie" and learned a lot more. Blender is the first software I'm learning. 
                                            I enrolled in some free YouTube courses and read Andy Beane's "3D Animation Essentials." 
                                            I learned the fundamentals of the 3D industry after three months.
                                            <br />
                                            <br />
                                            ♢ then <strong>Udemy</strong><br/>
                                            ---- I want to improve my sketching and 3D modeling skills after I've understood the fundamentals. I decided to purchase several Udemy courses. 
                                            My skills are progressively developing, and at the time, I was able to model 3D characters.
                                            <br />
                                            <br />
                                            ♢ and now <strong>CGBoost, P2Design</strong><br/>
                                            ---- Some of the models I've made aren't good enough to compete. They just stay there doing nothing; I want them to move, so I take two rigging and animation courses. 
                                            To gain a better knowledge of <strong>Extreme, In-between, Keyframe, Timing, Spacing</strong>, etc, 
                                            I read "The Animator's Survival Kit - Richard Williams." 
                                        </p>
                                    </div>
                                </div>
                                <div className="about-right">
                                    <div className="about-card">
                                    <LazyLoad>
                                    <img src={Pc_animator} alt="" className="about-img" />
                                    </LazyLoad>
                                </div>
                            </div>
                        </div>
                        <div className='about part_3'>
                            <div className="about-left">
                                <div className="about-card">
                                    <LazyLoad>
                                        <img src={Pc_director} alt="" className="about-img" />
                                    </LazyLoad>
                                </div>
                            </div>
                            <div className="about-right">
                                <div className="about-desc about-content" style={{paddingRight: '50px'}}>
                                    <h2>╰‿╯ And a Director</h2> <hr />
                                    <p>
                                    <br />
                                    ---- I love to read books, especially Novel. I read quite slowly because I always imagine every single line I've read.
                                    I make scenes in my mind very clearly. It may take me several minutes to visualize how things look and how the characters act as if I were watching a movie in my thoughts.
                                    <br /><br />
                                    ---- "Directing the Story" and "Cinematography: Theories and Practice" are two books I'm now reading. I want to make my own movies.
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                        <div className='about part_4'>
                            <div className="about-left">
                                <div className="about-desc about-content" style={{paddingLeft: '50px'}}>
                                    <h2>╰‿╯ Study is the first</h2> <hr />
                                    <p>
                                    <br />
                                    ---- I've participated and passed a University (Hanoi Academy of Theatre and Cinema) entrance exam to get the opportunity to learn and study about cinema there. This is the first step to achieving a dream.
                                    Become a director and make great movies.
                                    <br /><br />
                                    ---- I have three years to maximize my development.
                                    </p>
                                </div>
                            </div>
                            <div className="about-right">
                                <div className="about-card">
                                    <LazyLoad>
                                    {darkMode ? (<img src={SKDA_smoke} alt="" className="about-img" />):
                                    <img src={SKDA_dirt} alt="" className="about-img" />}
                                    </LazyLoad>
                                </div>
                            </div>
                            
                        </div>
                    </>
                ) 
                :
                (
                    <>
                        <div className='about part_2'>
                            <div className="about-left">
                                <div className="about-desc about-content" style={{paddingLeft: '50px'}}>
                                    <h2>╰‿╯ Work experiences (IT)</h2>
                                    <hr /><br />
                                    <h4>VHCSoft</h4>
                                    Java Backend Intern <br />
                                    Main responsibilities: <br />
                                        - Learn new technology that the company intends to do in the future.<br />
                                        - Maintain and develop some significant projects for the company.<br />
                                        - Attend Oracle course in the company.<br />
                                    <br />
                                    <hr />
                                    <br />
                                    <h4>Minerva</h4>
                                    PHP Backend Developer <br />
                                    Main responsibilities: <br />
                                        - Learn new technology that the company intends to do in the future.<br />
                                        - Learn how to control big data.<br />
                                        - Maintain and develop some significant projects for the company.<br />
                                    Achieved:<br />
                                        - Approach many technology and technique.<br />
                                        - Better teamwork, more disciplined.<br />
                                    <br />
                                    <hr />
                                </div>
                            </div>
                            <div className="about-right">
                                <div className="about-card">
                                    <LazyLoad>
                                    <img src={Pc_coding} alt="" className="about-img" />
                                    </LazyLoad>
                                </div>
                            </div>
                        </div>
                        <div className='about part_2'>
                            <div className="about-left">
                                <div className="about-card">
                                    <LazyLoad>
                                    <img src={S_Connect} alt="" className="about-img" />
                                    </LazyLoad>
                                </div>
                            </div>
                            <div className="about-right">
                                <div className="about-desc about-content" style={{paddingLeft: '50px'}}>
                                    <h2>╰‿╯ Work experiences (3D Animator)</h2>
                                    <hr /><br />
                                    <h4>SCONNECT VIETNAM TECHNOLOGY INVESTMENT AND SERVICES CO., LTD</h4>
                                    3D Animator (Jul 5th, 2022 - Present)<br /><br />
                                    <h2>PROJECT: Minecraft</h2>
                                    <br />
                                    Main responsibilities: <br />
                                        - Read the screenplay, analyze each scene, and start animating.<br />
                                        - Discuss every week to improve the quality of each product for each group.<br />
                                        - Participate in some internal classes to improve myself, hard skills and soft skills.<br />
                                        - Always have to work with other production teams to get the finest quality of the product.<br /><br />
                                    Achieved:<br />
                                        - Knowledge of
                                        <ul>
                                            <li>Youtube: CTR, Demographics, Average View Duration, Audience Retention.</li>
                                            <li>Cinematography: shot sizes, camera angles, camera movements, cuts, transitions.</li>
                                            <li>Screenwriting: story convention, structure, characters, and genres. </li>
                                        </ul><br />
                                        - From soft skill classes, I get better in presentation, more confident in conversation, manage time more effectively and improve teamwork skills.<br />
                                        - Working in a young and dynamic environment. Easily maximize your abilities. And have a pretty great leader.<br />
                                        - I also participated in voice talent competition organized by the Wolfoo Series.
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                        <div className='about part_2'>
                            <div className="about-left">
                                <div className="about-desc about-content" style={{paddingLeft: '50px'}}>
                                    <h2>PROJECT: Birdy Friends</h2>
                                    <hr /><br />
                                    Birdy Friends is a glocal (global+local) and eco-cultural content born and bred on Jeju Island. 
                                    It consists of the characters of five birds native to Jeju, including an endangered species.
                                    Pitta (Fairy Pitta), White (Japanese White-eye), Kasker (Peregrine Falcon), Zelda (Eurasian Skylark) 
                                    and Woody (White-backed Woodpecker) venture and grow together in Jeju's mysterious forest 
                                    and tell us a variety of forest stories.<br /><br />
                                    ● <b>Pitta</b>: Pitta loves going on adventures and making new friends. And when he finds someone in trouble, he often takes the lead to save the day.<br />
                                    ● <b>White</b>: White is an avid reader who has been dubbed a walking encyclopedia by his friends. He’s always the first to provide his friends 
                                    with new information and knowledge.<br />
                                    ● <b>Kasker</b>: The strongest of the five birdy friends, Kasker is a dependable fellow who’s always the first to stand up for justice. He is also a gourmet 
                                    willing to go anywhere in search of good food.<br />
                                    ● <b>Zelda</b>: Zelda’s artistic flair and talent combined with her special powers of empathy enable her to communicate with nature as she collects stones, flower petals and berries, 
                                    which she turns into works of art that she sometimes gives to her friends as gifts.<br />
                                    ● <b>Woody</b>: Woody is a white-backed woodpecker that is the symbolic bird of Jeju. He likes to take things apart 
                                    and reassemble them to create something new and different, and excels at thinking three-dimensionally. 
                                </div>
                            </div>
                            <div className="about-right">
                                <div className="about-card">
                                    <LazyLoad>
                                        <img src={Birdy} alt="" className="about-img" />
                                    </LazyLoad>
                                </div>
                            </div>
                        </div>
                        <div className='about part_2'>
                            <div className="about-left">
                                <div className="about-card">
                                    <LazyLoad>
                                        {darkMode ? (<img src={WF} alt="" className="about-img" />):
                                        <img src={WF_s} alt="" className="about-img" />}
                                    </LazyLoad>
                                </div>
                            </div>
                            <div className="about-right">
                                <div className="about-desc about-content" style={{paddingLeft: '50px'}}>
                                    <h2>PROJECT: WOLFOO 3D</h2>
                                    <hr /><br />
                                    We will provide children with engaging experiences through stories centered on <br />Wolfoo's the world,
                                    as well as amusing and informative animated episodes <br />appropriate for children as young as two up to
                                    eight years old: <br />Encourage creativity, develop healthy habits, and self-care; life skills, and children's
                                    cultural awareness. <br /><br />
                                    We want to contribute to the development of a generation of Vietnamese youngsters who
                                    are well-rounded intellectually and ethically.
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default About;