import React, {useState} from "react";
import PropTypes from "prop-types";
import ReactCardFlip from 'react-card-flip';
import './YoutubeEmbed.css';

const YoutubeEmbed = ({ key, embedId, about }) => {
    const [IsFlipped, setIsFlipped] = useState(false);

    const handleMouseover = () => {
        setIsFlipped(!IsFlipped);
    }

    return (
        <div className="video-responsive">
            <ReactCardFlip isFlipped={IsFlipped} flipDirection="horizontal">
                <div className="front">
                    <div onClick={handleMouseover} className='product-about'>
                        <div className='product-about-dot'>•••</div>
                    </div>
                    <iframe
                        width="420"
                        height="245"
                        src={`https://www.youtube.com/embed/${embedId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
                <div className="back">
                    <div onClick={handleMouseover} className='product-about'>
                        <div className='product-about-dot'>•••</div>
                    </div>
                    <p className='product-back-about'>{about}</p>
                </div>
            </ReactCardFlip>
            
        </div>
    )
};

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;