import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import About from './components/about/About.jsx';
import Intro from './components/introduction/Intro.jsx';
import ProductList from './components/productList/ProductList.jsx';
import Contact from './components/contact/Contact.jsx';
import Toggle from './components/toggle/Toggle.jsx';
import Header from './components/header/Header.jsx';
import { ThemeContext } from './context.js';

import "./App.css";

const App = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div className="app" style={{backgroundColor:darkMode ? "#222222" : "white", color:darkMode ? "white" : "black"}}>
            <Header />
            <Toggle />

            <Routes>
                <Route path="/" element={<Intro />} />
                <Route path="/about" element={<About />} />
                <Route path="/products" element={<ProductList />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </div>
    );
}

export default App;