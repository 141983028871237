import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { ThemeContext } from '../../context';

const Header = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div className='header' style={
            {backgroundColor: darkMode ? "#282828" : "#ffffff",
                boxShadow: !darkMode ? "0px 1px 2px 1px #6e6e6e" : "0px 1px 3px 1px #111111"
            }
            }>
            <nav className='header-nav'>
                <ul className='header-list'>
                    <ol>
                        <Link className='header-route' style={{color: !darkMode ? "black" : "white"}} to="/">Home</Link>
                    </ol>
                    <ol>
                        <Link className='header-route' style={{color: !darkMode ? "black" : "white"}} to="/about">About me</Link>
                    </ol>
                    <ol>
                        <Link className='header-route' style={{color: !darkMode ? "black" : "white"}} to="/products">Products</Link>
                    </ol>
                    <ol>
                        <Link className='header-route' style={{color: !darkMode ? "black" : "white"}} to="/contact">Contact</Link>
                    </ol>
                </ul>
            </nav>
        </div>
    )
}

export default Header;