import React, { useRef, useState, useContext } from 'react';
import emailjs from '@emailjs/browser';
import { ThemeContext } from '../../context';
import './Contact.css';

const Contact = () => {
    const formRef = useRef();
    const [done, setDone] = useState(false);
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_s33zc9t', 'template_ipyfvzb', formRef.current, 'user_WCzNBYThcz34CHNkqQVau')
            .then((result) => {
                console.log(result.text);
                setDone(true);
                window.location.reload(false);
            }, (error) => {
                console.log(error.text);
            }
        );
    }

    return (
        <div className='contact'>
            <div className="contact-bg"></div>
            <div className="contact-wrapper">
                <div className="contact-left">
                    <h1 className="contact-title">Let's discuss your project</h1>
                    <div className="contact-info">
                        <div className="contact-info-item">
                            <i class="fas fa-mobile-alt contact-icon phone" />
                            +84 886 360 476
                        </div>
                        <div className="contact-info-item">                            
                            <i class="fas fa-envelope contact-icon email" />
                            thdang2812@gmail.com
                        </div>
                        <div className="contact-info-item">
                            <i class="fas fa-map-marker-alt contact-icon address" />
                            Nam Tu Liem, Ha Noi
                        </div>
                    </div>
                </div>
                <div className="contact-right">
                    <div className="contact-desc">
                        <b>What's your story?</b> Get in touch. Always available for freelancing if the right project comes along me.
                    </div>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <input type='text' placeholder='Name' name='user_name' 
                            style={
                                {backgroundColor: darkMode ? "#222222" : "white",
                                color: darkMode ? "white" : "black",
                                borderBottom: darkMode ? "1px solid white" : "1px solid black"}
                            } />
                        <input type='text' placeholder='Subject' name='user_subject' 
                            style={
                                {backgroundColor: darkMode ? "#222222" : "white", 
                                color: darkMode ? "white" : "black",
                                borderBottom: darkMode ? "1px solid white" : "1px solid black"}
                            } />
                        <input type='text' placeholder='Email' name='user_email' 
                            style={
                                {backgroundColor: darkMode ? "#222222" : "white",
                                color: darkMode ? "white" : "black",
                                borderBottom: darkMode ? "1px solid white" : "1px solid black"}
                            } />
                        <textarea rows='5' placeholder='Message' name='message' 
                            style={
                                {backgroundColor: darkMode ? "#222222" : "white",
                                color: darkMode ? "white" : "black",
                                borderBottom: darkMode ? "1px solid white" : "1px solid black"}
                            } />
                        <button style={{backgroundColor: done ? "#10ac84" : "rgb(255,88,88)"}}>Send</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
