import React, {useContext} from 'react';
import { ThemeContext } from '../../context.js';
import './Toggle.css';
const Toggle = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    const handleClick = () => {
        theme.dispatch({type: "TOGGLE!"});
    };

    return (
        <div className="toggle" style={{backgroundColor: darkMode ? "#222" : "white"}}>
            <div className="toggle-icon">
                <i class="fas fa-sun" />
            </div>
            <div className="toggle-icon">
                <i class="fas fa-moon" />
            </div>
            <div className="toggle-button" 
                onClick={handleClick} 
                style={{left: darkMode ? 0 : 25}}>
            </div>
        </div>
    );
};

export default Toggle;
