import React, { useContext } from 'react';
import TypeAnimation from 'react-type-animation';
import { ThemeContext } from '../../context';
import './Intro.css';
import Me from '../../img/pc-boxing.webp';

const Intro = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div className='intro'>
            <div className="intro-left">
                <div className="intro-left-wrapper">
                    <h2>Hello, My name is</h2>
                    <h1 className='intro-name'>Nguyen Hai Dang</h1>
                    <div className="intro-title">
                        <div className="intro-title-wrapper">
                            <div className="intro-title-item">
                                3D Animator
                            </div>
                        </div>
                    </div>
                    <TypeAnimation
                        className="intro-desc"
                        cursor={false}
                        sequence={["My name's Dang but you can call me Four. I'm glad about your appearance here. I'm 22 years old.", 3000,
                                    "Take a look around my website!", 3000, 
                                    "Hope you like it!", 3000, "My name's Dang but you can call me Four. I'm glad about your appearance here. I'm 23 years old."]}
                        wrapper="h2"
                        repeat="Infinity"
                    />
                </div>

            </div>
            <div className="intro-right">
                <div style={{backgroundColor: darkMode ? "#222222" : "#404040"}} className="intro-bg-1"></div>
                <img src={Me} alt="Handsome guy" className="intro-img" width="620px"/>
            </div>
        </div>
    );
};

export default Intro;
