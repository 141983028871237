import React, {useState} from 'react';
import LazyLoad from 'react-lazy-load';
import ReactCardFlip from 'react-card-flip';
import './Product.css';

const Product = ({img, link, about}) => {
    const [IsFlipped, setIsFlipped] = useState(false);

    const handleClick = () => {
        setIsFlipped(!IsFlipped);
    }

    return (
        <div className="product">
            <ReactCardFlip isFlipped={IsFlipped} flipDirection="vertical">
                <div className='product-font'>
                    <div onClick={handleClick} className='product-about'>
                        <div className='product-about-dot'>•••</div>
                    </div>
                    <div target='_blank' rel='noreferrer'>
                        <LazyLoad height={img.height} width={img.width}>
                            <img src={img} alt="" className='product-img' />
                        </LazyLoad>
                        
                    </div>
                </div>

                <div className='product-back'>
                    <div onClick={handleClick} className='product-about'>
                        <div className='product-about-dot'>•••</div>
                    </div>
                    <p className='product-back-about'>{about}</p>
                </div>
            </ReactCardFlip>
        </div>
    );
};

export default Product;