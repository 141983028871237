export const products_light = [
    {
        id: 1,
        img: 'https://cdnb.artstation.com/p/assets/images/images/044/583/873/large/f0ur-pluto.jpg?1640511705',
        link: 'https://f0ur.artstation.com/',
        about: 'Baby Pluto'
    },
    {
        id: 2,
        img: 'https://cdna.artstation.com/p/assets/images/images/044/607/652/large/f0ur-litoir-green.jpg?1640600101',
        link: 'https://f0ur.artstation.com/',
        about: 'The creature in my imagination' 
    },
    {
        id: 3,
        img: 'https://cdnb.artstation.com/p/assets/images/images/045/048/879/large/f0ur-landmount-reduce.jpg?1641816620',
        link: 'https://f0ur.artstation.com/',
        about: 'Sketch from Renee Roaming\'s picture'
    },
    {
        id: 4,
        img: 'https://cdnb.artstation.com/p/assets/images/images/063/779/887/large/f0ur-humanian.jpg?1686325732',
        link: 'https://f0ur.artstation.com/',
        about: 'Hmmmmmmm'
    },
    {
        id: 5,
        img: 'https://cdnb.artstation.com/p/assets/images/images/049/674/559/small/f0ur-johnwick.jpg?1653038916',
        link: 'https://f0ur.artstation.com/',
        about: 'John Wick'
    },
    {
        id: 6,
        img: 'https://cdnb.artstation.com/p/assets/images/images/049/347/605/small/f0ur-tommy.jpg?1652283591',
        link: 'https://f0ur.artstation.com/',
        about: 'Peaky "f*cking" Blinders'
    },
    {
        id: 7,
        img: 'https://cdnb.artstation.com/p/assets/images/images/049/457/427/small/f0ur-kong.jpg?1652541449',
        link: 'https://f0ur.artstation.com/',
        about: 'Jack Sparrow in da hood'
    },
    {
        id: 8,
        img: 'https://cdnb.artstation.com/p/assets/images/images/049/474/583/small/f0ur-chaplin.jpg?1652595092',
        link: 'https://f0ur.artstation.com/',
        about: 'Charlie Chaplin'
    },
    {
        id: 9,
        img: 'https://cdnb.artstation.com/p/assets/images/images/049/478/427/small/f0ur-lupin.jpg?1652609492',
        link: 'https://f0ur.artstation.com/',
        about: 'Arsène Lupin'
    },
    {
        id: 10,
        img: 'https://cdnb.artstation.com/p/assets/images/images/049/674/441/small/f0ur-holmes.jpg?1653038696',
        link: 'https://f0ur.artstation.com/',
        about: 'Sherlock Holmes'
    },
    {
        id: 11,
        img: 'https://cdnb.artstation.com/p/assets/images/images/049/766/939/small/f0ur-joker.jpg?1653293805',
        link: 'https://f0ur.artstation.com/',
        about: 'Joker'
    },
    {
        id: 12,
        img: 'https://cdnb.artstation.com/p/assets/images/images/063/702/435/large/f0ur-crococo.jpg?1686153483',
        link: 'https://f0ur.artstation.com/',
        about: 'Cat'
    },
    {
        id: 13,
        img: 'https://cdna.artstation.com/p/assets/images/images/063/862/728/large/f0ur-pumpkin00.jpg?1686568068',
        link: 'https://f0ur.artstation.com/',
        about: 'Pumpkin'
    },
    {
        id: 14,
        img: 'https://cdna.artstation.com/p/assets/images/images/064/585/140/small/f0ur-assian.jpg?1688290511',
        link: 'https://f0ur.artstation.com/',
        about: 'Pumpkin'
    }
];

export const products_night = [
    {
        id: 1,
        img: 'https://cdna.artstation.com/p/assets/images/images/046/072/374/large/f0ur-landscape.jpg?1644245236',
        link: 'https://f0ur.artstation.com/',
        about: 'Sketch from Renee Roaming\'s picture'
    },
    {
        id: 2,
        img: 'https://cdnb.artstation.com/p/assets/images/images/049/676/741/small/f0ur-starry-night.jpg?1653043953',
        link: 'https://f0ur.artstation.com/',
        about: 'The starry day'
    }
]

export const products_3d_character_light = [
    {
        id: 1,
        img: 'https://cdna.artstation.com/p/assets/images/images/046/058/718/large/f0ur-mg-light.jpg?1644218615',
        link: 'https://f0ur.artstation.com/',
        about: 'This idea comes to me when I see the vase and I wonder "How does it look if I put the human head on top?". I imagine, I sketch, and then I model it'
    },
    {
        id: 2,
        img: 'https://cdnb.artstation.com/p/assets/images/images/046/058/753/large/f0ur-octopus-light.jpg?1644218707',
        link: 'https://f0ur.artstation.com/',
        about: 'One of my favorite animals - the Octopus'
    },
    {
        id: 3,
        img: 'https://cdnb.artstation.com/p/assets/images/images/046/111/615/large/f0ur-husky.jpg?1644329843',
        link: 'https://f0ur.artstation.com/',
        about: 'Inspired by a stuffed husky'
    },
]

export const products_3d_character_night = [
    {
        id: 1,
        img: 'https://cdnb.artstation.com/p/assets/images/images/049/154/551/small/f0ur-monster-night-bg.jpg?1651804542',
        link: 'https://f0ur.artstation.com/',
        about: 'The monster I\'ve sketched.'
    },
    {
        id: 2,
        img: 'https://cdna.artstation.com/p/assets/images/images/046/071/622/small/f0ur-ghost-night.jpg?1644244215',
        link: 'https://f0ur.artstation.com/',
        about: 'A ghost with crystals hair - is genarated by Geometry Node'
    },
    {
        id: 3,
        img: 'https://cdnb.artstation.com/p/assets/images/images/068/844/091/small/f0ur-monk-night.jpg?1698768915',
        link: 'https://f0ur.artstation.com/',
        about: 'A monk'
    },
]

export const products_3d_animation = [
    {
        id: 123,
        embedLink: '2tncslDLek0',
        about: 'Loopoo'
    },
    {
        id: 13,
        embedLink: 'Ausva5LzVOM',
        about: 'Tigeere'
    },
    {
        id: 1,
        embedLink: 'fkGIwC9RetE',
        about: 'Mario'
    },
    {
        id: 2,
        embedLink: 'IMUj4mQNx3o',
        about: 'Zombie'
    },
    {
        id: 7,
        embedLink: '65YtiOoIyNk',
        about: 'Birdy Friends'
    },
    {
        id: 11,
        embedLink: 'sP_yojSCCIg',
        about: 'My Luxo version' 
    },
    {
        id: 12,
        embedLink: 'hQjR57at6-0',
        about: 'Football match' 
    }
    ,
    {
        id: 9,
        embedLink: 'umEGDK-vvcQ',
        about: 'Wolfoo'
    },
    {
        id: 6,
        embedLink: 'ta81xe9qE2Q',
        about: 'Exercise - P2 - 2'
    },

    {
        id: 8,
        embedLink: 'nNsCTk7comM',
        about: 'Wolfoo'
    }
]